import React, { useState, useEffect } from 'react';
import { useLoading } from "../components/common/LoadingContext"
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/auth/AuthContext';
import { FaDownload } from 'react-icons/fa';
import { doc, updateDoc } from 'firebase/firestore';
import { db, functions } from '../components/auth/Config';
import { Reservation } from '../components/reservations/Reservation';
import { Product } from '../components/common/ListBuilder';
import { MdCancel } from 'react-icons/md';
import { useReservation } from '../components/common/ReservationProvider';
import { httpsCallable } from 'firebase/functions';
import { CalendarResultData, createCalendarEvent } from '../components/common/Calendar';
import { FaCommentDots } from 'react-icons/fa';
import DeleteMenu from '../components/common/DeleteMenu';
import { Commentary } from '../components/reservations/Commentary';
import { generateConfirmationEmail } from '../components/common/EmailTemplates';
import generateInvoiceFrom from '../components/common/PdfTemplate';


const NewReservation: React.FC = () => {

  const navigate = useNavigate();
  const { user, accessToken, setAccessToken } = useAuth();
  const { loading, setLoading, setLoadingMessage } = useLoading();
  const [productList, setProductList] = useState<Product[]>([]);
  const [newProductTitle, setNewProductTitle] = useState('');
  const [newProductQuantity, setNewProductQuantity] = useState(1);
  const [newProductPrice, setNewProductPrice] = useState(0);
  const { reservation } = useReservation();
  
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nip, setNip] = useState<number>(0);
  const [country, setCountry] = useState<string>('CH');

  const [comment, setComment] = useState('');
  const [comments, setComments] = useState<Commentary[]>([]);

  // Déclaration des états pour les options et leurs tarifs
  const [addDelivery, setAddDelivery] = useState<boolean>(false);
  const [addPickup, setAddPickup] = useState<boolean>(false);
  const [deliveryAddress, setDeliveryAddress] = useState<string>('');
  const [pickupAddress, setPickupAddress] = useState<string>('');
  const [installation, setInstallation] = useState<boolean>(false);
  const [adjustment, setAdjustment] = useState<boolean>(false);
  const [dismantling, setDismantling] = useState<boolean>(false);
  const [onSite, setOnSite] = useState<boolean>(false);
  const [note, setNote] = useState<string>('');
  const [discount, setDiscount] = useState<boolean>(false);
  const [confirmedReservation, setConfirmedReservation] = useState<Reservation | null>(null);
  const [pickupDate, setPickupDate] = useState<string>('');
  const [returnDate, setReturnDate] = useState<string>('');
  const [depositMethod, setDepositMethod] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<string>('');
  const [rentalCoefficient, setRentalCoefficient] = useState<number>(1);
  const [productTotal, setProductproductTotal] = useState<number>(0);
  const [depositAmount, setDepositAmount] = useState<number>(200);
  const [calculatedSum, setCalculatedSum] = useState<number>(0);
  const [isAddToCalendarChecked, setIsAddToCalendarChecked] = useState(true);
  const [isSendConfirmationChecked, setIsSendConfirmationChecked] = useState(true);
  const [isIncludeQRFacture, setIsIncludeQRFacture] = useState(true);
  const [isIncludeQuote, setIsIncludeQuote] = useState(false);
  const [isSumCorrect, setIsSumCorrect] = useState(true);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const [invoiceRecipient, setInvoiceRecipient] = useState<string>('');

  const [deliveryPrice, setDeliveryPrice] = useState<string>('');
  const [installationPrice, setInstallationPrice] = useState<string>('');
  const [setupPrice, setSetupPrice] = useState<string>('');
  const [onSiteDuration, setOnSiteDuration] = useState<string>('');
  const [onSitePrice, setOnSitePrice] = useState<string>('');
  const [dismantlingPrice, setDismantlingPrice] = useState<string>('');
  const [pickupPrice, setPickupPrice] = useState<string>('');
  const [discountPrice, setDiscountPrice] = useState<string>('');



  // Fonctions pour gérer les changements sur les cases à cocher
  const handleAddDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddDelivery(event.target.checked);
  };

  const handleAddPickupChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddPickup(event.target.checked);
  };

  const handleInstallationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInstallation(event.target.checked);
  };

  const handleAdjustmentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAdjustment(event.target.checked);
  };

  const handleDismantlingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDismantling(event.target.checked);
  };

  const handleOnSiteChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOnSite(event.target.checked);
  };

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscount(event.target.checked);
  };


  useEffect(() => {
    if (reservation) {
      const reservationCopy: Reservation = { ...reservation };
      setConfirmedReservation(reservationCopy);
      setPickupDate(reservation.pickupDate.substring(0, 16));
      setReturnDate(reservation.returnDate.substring(0, 16));
      setFirstName(reservation.firstName);
      setLastName(reservation.lastName);
      setDepositAmount(reservation.depositAmount || 200);
      setNip(reservation.nip || 0);
      setCountry(reservation.country || 'CH');
      setEmail(reservation.email);
      setNote(reservation.note || '');
      setDepositMethod(reservation.depositMethod || '');
      setPaymentMethod(reservation.paymentMethod || '');
      setPhoneNumber(reservation.phoneNumber);
      setInvoiceRecipient(reservation.invoiceRecipient || `${reservation.firstName} ${reservation.lastName}`);
      setRentalCoefficient(reservation.rentalCoefficient);
      setProductproductTotal(reservation.productTotal || reservation.total || 0);
      setCalculatedSum(reservation.productTotal || 0); // Not true, but erased by other useeffect
      if (reservation?.productList) {
        try {
          const parsedProductList: Product[] = JSON.parse(reservation.productList);
          setProductList(parsedProductList);
        } catch (error) {
          console.error("Parsing error:", error);
          setProductList([]);
        }
      }
      if (reservation?.comments) {
        setComments(reservation.comments);
      }

      setPickupAddress(reservation.pickupAddress ||"");
      setDeliveryAddress(reservation.deliveryAddress || "");
      setOnSiteDuration(reservation.onSiteDuration?.toString() || '');
      setAddDelivery(reservation.addDelivery || false);
      setDeliveryPrice(reservation.deliveryFee?.toString() || '');
      setInstallation(reservation.installation || false);
      setInstallationPrice(reservation.installationFee?.toString() || '');
      setAdjustment(reservation.adjustment || false);
      setSetupPrice(reservation.adjustmentFee?.toString() || '');
      setIsIncludeQRFacture(reservation.paymentMethod === "Facture");
      setOnSite(reservation.onSite || false);
      setOnSitePrice(reservation.onSiteFee?.toString() || '');
      setDismantling(reservation.dismantling || false);
      setDismantlingPrice(reservation.dismantlingFee?.toString() || '');
      setAddPickup(reservation.addPickup || false);
      setPickupPrice(reservation.pickupFee?.toString() || '');
      setDiscount(reservation.discount || false);
      setDiscountPrice(reservation.discountAmount?.toString() || '');
    }
    
  }, [reservation]);

  useEffect(() => {
    let temp = 0;
    if (addDelivery){
      temp += Number(deliveryPrice);
    }
    if (installation) {
      temp += Number(installationPrice);
    }
    if (adjustment) {
      temp += Number(setupPrice);
    }
    if (onSite) {
      temp += Number(onSitePrice) * Number(onSiteDuration);
    }
    if (dismantling) {
      temp += Number(dismantlingPrice);
    }
    if (addPickup) {
      temp += Number(pickupPrice);
    }
    if (discount) {
      temp -= Number(discountPrice);
    }
    setCalculatedSum(temp + productTotal);
    validateProductTotal();
  }, [productList, deliveryPrice, installationPrice, setupPrice, onSitePrice, dismantlingPrice, pickupPrice, discountPrice, productTotal, addDelivery, installation, adjustment, onSite, dismantling, addPickup, discount])


  const validateProductTotal = () => {
    const calculatedTotal = productList.reduce((sum, product) => {
      return sum + product.quantity * product.price;
    }, 0);
  
    setIsSumCorrect(calculatedTotal === productTotal);
  };

  
  const addComment = async () => {
    if (!comment.trim()) return;
    if (!reservation) return;

    setLoading(true, "Ajout");

    const newComment = {
      id: `comment${comments.length + 1}`,
      text: comment,
      timestamp: new Date().toISOString(),
    };

    const updatedComments = [...comments, newComment];
    setComments(updatedComments);
    setComment('');
    try {
      await updateDoc(doc(db, 'reservations', reservation.id), {
        comments: updatedComments,
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout du commentaire', error);
      alert('Erreur lors de l\'ajout du commentaire: \n\n' + error);
    }

    setLoading(false);
  };

  const handleStatusChange = async (status: string) => {

    if (!reservation) {
      console.error("currentReservation est nulle");
      alert('Erreur de donnée, recharger la page');
      return;
    }

    setLoading(true, "Enregistrement");

    try {
      const reservationRef = doc(db, "reservations", reservation.id);
      await updateDoc(reservationRef, {
        status: status,
        modifiedDate: new Date().toISOString(),
      });
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la réservation', error);
      alert('Erreur lors de l\'ajout de la réservation: \n\n' + error);
      setLoading(false);
    }

    setLoading(false);

    navigate('/');
  
  };


  const handleAddProduct = () => {
    const newProduct: Product = {
      title: newProductTitle,
      quantity: newProductQuantity,
      price: newProductPrice
    };
    setProductList([...productList, newProduct]);
    setNewProductTitle('');
    setNewProductQuantity(1);
    setNewProductPrice(0);
  };

  const generateUpdatedReservation = () => { 
    // Assuming confirmedReservation and other variables are available and correctly defined.

    const updatedReservation: Reservation = {
      id: confirmedReservation!.id || "default-id",  // Use the id from confirmedReservation or a default value
      reservationNumber: confirmedReservation!.reservationNumber,
      pickupDate: pickupDate,
      returnDate: returnDate,
      status: confirmedReservation!.status,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      paymentMethod: paymentMethod,  // Assuming this is defined
      paymentAmount: confirmedReservation!.paymentAmount, // Optional field, keep existing or undefined
      depositMethod: depositMethod,  // Assuming this is defined
      depositAmount: depositAmount,  // Assuming this is defined
      depositReturnMethod: confirmedReservation!.depositReturnMethod, // Keep existing or undefined
      depositReturnAmount: confirmedReservation!.depositReturnAmount, // Keep existing or undefined
      documentURL: confirmedReservation!.documentURL, // Keep existing or undefined
      returnNote: confirmedReservation!.returnNote,  // Keep existing or undefined
      dDayNote: confirmedReservation!.dDayNote,  // Keep existing or undefined
      rentalCoefficient: rentalCoefficient,  // Assuming this is defined
      customerMessage: confirmedReservation!.customerMessage, // Keep existing or undefined
      productList: JSON.stringify(productList), // Convert productList to string
      productTotal: productTotal,  // Assuming this is defined
      calculatedSum: calculatedSum,  // Assuming this is defined
      knownFrom: confirmedReservation!.knownFrom,  // Keep existing or undefined
      userId: confirmedReservation!.userId,  // Assuming this is defined
      nip: nip,  // Assuming this is defined
      country: country,  // Assuming this is defined
      addDelivery: addDelivery,  // Assuming this is defined
      addPickup: addPickup,  // Assuming this is defined
      deliveryAddress: deliveryAddress,  // Assuming this is defined
      pickupAddress: pickupAddress,  // Assuming this is defined
      deliveryFee: Number(deliveryPrice),  // Calculated value
      pickupFee: Number(pickupPrice),  // Calculated value
      installation: installation,  // Assuming this is defined
      installationFee: Number(installationPrice),  // Calculated value
      adjustment: adjustment,  // Assuming this is defined
      adjustmentFee: Number(setupPrice),  // Calculated value
      dismantling: dismantling,  // Assuming this is defined
      dismantlingFee: Number(dismantlingPrice),  // Calculated value
      contractLink: confirmedReservation!.contractLink,  // Keep existing or undefined
      productListLink: confirmedReservation!.productListLink,  // Keep existing or undefined
      onSite: onSite,  // Assuming this is defined
      onSiteDuration: Number(onSiteDuration),  // Assuming this is defined
      onSiteFee: Number(onSitePrice),  // Calculated value
      discount: discount,  // Assuming this is defined
      discountAmount: Number(discountPrice),  // Calculated value
      creationDate: confirmedReservation!.creationDate,  // Assuming this is defined
      modifiedDate: new Date().toISOString(),  // Use current date/time
      comments: confirmedReservation!.comments,  // Keep existing or undefined
      invoiceRecipient
    };
    return updatedReservation;
  }

  const segmentString = (str: string, length: number): string => {
    let result = '';
    let currentLength = 0;
  
    const words = str.split(' ');
  
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
  
      // Check if adding this word would exceed the desired length
      if (currentLength + word.length > length) {
        result += '\n';
        currentLength = 0; // Reset the line length
      }
  
      // Add the word to the result
      if (currentLength > 0) {
        result += ' ';
        currentLength++; // Account for the space
      }
  
      result += word;
      currentLength += word.length;
    }
  
    return result;
  };
  
  

  const generateInvoicePdf = async (isInvoice : boolean) => {

    // Generate the PDF blob using the fillPdfTemplate function
    const pdfBlob = await generateInvoiceFrom(generateUpdatedReservation(), isInvoice, segmentString(invoiceRecipient, 20));

    // Create a URL for the generated PDF
    const url = URL.createObjectURL(pdfBlob);
    setPdfUrl(url);
  };

  const handleQuantityChange = (index: number, quantity: number) => {
    const updatedList = productList.map((item, idx) => idx === index ? { ...item, quantity } : item);
    setProductList(updatedList);
  };

  const handlePriceChange = (index: number, price: number) => {
    const updatedList = productList.map((item, idx) => idx === index ? { ...item, price } : item);
    setProductList(updatedList);
  };

  const handleProductNameChange = (index: number, title: string) => {
    const updatedList = productList.map((item, idx) => idx === index ? { ...item, title } : item);
    setProductList(updatedList);
  };

  const handleRemoveProduct = (index: number) => {
    const newList = productList.filter((_, i) => i !== index);
    setProductList(newList);
  };

    // Function to download the invoice
    const downloadInvoice = async () => {
      try {
        // Generate the invoice PDF
        const pdfBlob = await generateInvoiceFrom(generateUpdatedReservation(), true, segmentString(invoiceRecipient, 20));
        
        // Create a URL for the PDF Blob
        const url = URL.createObjectURL(pdfBlob);
        
        // Create an anchor element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Facture_AirSound.pdf'; // Name the file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error generating or downloading the invoice PDF:', error);
        alert('An error occurred while generating or downloading the invoice.');
      }
    };
  
    // Function to download the quote
    const downloadQuote = async () => {
      try {
        // Generate the quote PDF
        const pdfBlob = await generateInvoiceFrom(generateUpdatedReservation(), false, segmentString(invoiceRecipient, 20));
        
        // Create a URL for the PDF Blob
        const url = URL.createObjectURL(pdfBlob);
        
        // Create an anchor element and trigger a download
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Devis_AirSound.pdf'; // Name the file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error generating or downloading the quote PDF:', error);
        alert('An error occurred while generating or downloading the quote.');
      }
    };


  const handleSaveOnly = async () => {
    if (!user) {
      console.error("User est nul");
      alert('Vous n\'êtes plus connecté');
      return;
    }
  
    if (!confirmedReservation) {
      console.error("ConfirmedReservation est null");
      alert('Erreur de transfer de donnée, recharger la page');
      return;
    }
  
    if (new Date(pickupDate) >= new Date(returnDate)) {
      alert('La date de prise en charge doit être avant la date de retour');
      return;
    }
  
    setLoading(true, "Enregistrement");
  
    const updatedReservation = {
      ...confirmedReservation,
      productList: JSON.stringify(productList),
      pickupDate,
      returnDate,
      rentalCoefficient,
      productTotal,
      paymentMethod,
      depositAmount,
      depositMethod,
      modifiedDate: new Date().toISOString(),
      note,
      calculatedSum,
      firstName,
      lastName,
      email,
      phoneNumber,
      nip,
      country,
      addDelivery,
      deliveryAddress,
      deliveryFee: Number(deliveryPrice),
      installation,
      installationFee: Number(installationPrice),
      adjustment,
      adjustmentFee: Number(setupPrice),
      onSite,
      onSiteDuration: Number(onSiteDuration),
      onSiteFee: Number(onSitePrice),
      dismantling,
      dismantlingFee: Number(dismantlingPrice),
      addPickup,
      pickupAddress,
      pickupFee: Number(pickupPrice),
      discount,
      discountAmount: Number(discountPrice),
      invoiceRecipient,
    };
  
    try {
      const updateRef = doc(db, "reservations", confirmedReservation.id);
      await updateDoc(updateRef, updatedReservation);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la réservation', error);
      alert('Erreur lors de la mise à jour de la réservation: \n\n' + error);
    } finally {
      setLoading(false);
    }
  };
  

  const sendConfirmation = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    // Use FormData to get form values
    const formData = new FormData(event.currentTarget);
    // Retrieve the value of the "mailnote" field
    const mailNote = formData.get('mailnote')?.toString().trim() || ''; // Use the name attribute of the textarea


    if (!user) {
      console.error("User est nul");
      alert('Vous n\'êtes plus connecté');
      return;
    }

    if (!confirmedReservation) {
      console.error("ConfirmedReservation is null");
      alert('Erreur de transfer de donnée, recharger la page');
      return;
    }

    if (productList.length === 0) {
      alert('Veuillez ajouter au moins un produit à la réservation');
      return;
    }

    if (new Date(pickupDate) >= new Date(returnDate)) {
      alert('La date de prise en charge doit être avant la date de retour');
      return;
    }

    setLoading(true, "Enregistrement");

    confirmedReservation.productList = JSON.stringify(productList);
    confirmedReservation.pickupDate = pickupDate;
    confirmedReservation.returnDate = returnDate;
    confirmedReservation.rentalCoefficient = rentalCoefficient;
    confirmedReservation.productTotal = productTotal;
    confirmedReservation.paymentMethod = paymentMethod;
    confirmedReservation.depositAmount = depositAmount;
    confirmedReservation.depositMethod = depositMethod;
    confirmedReservation.modifiedDate = new Date().toISOString();
    confirmedReservation.note = note;
    confirmedReservation.calculatedSum = calculatedSum;
    confirmedReservation.firstName = firstName;
    confirmedReservation.lastName = lastName;
    confirmedReservation.email = email;
    confirmedReservation.phoneNumber = phoneNumber;
    confirmedReservation.nip = nip;
    confirmedReservation.country = country;
    confirmedReservation.status = "confirmed";
    confirmedReservation.invoiceRecipient = invoiceRecipient;


    if (addDelivery) {
      confirmedReservation.addDelivery = true;
      confirmedReservation.deliveryAddress = deliveryAddress;
      confirmedReservation.deliveryFee = Number(deliveryPrice);
    } else {
      confirmedReservation.addDelivery = false;
    }
  
    if (installation) {
      confirmedReservation.installation = true;
      confirmedReservation.installationFee = Number(installationPrice);
    } else {
      confirmedReservation.installation = false;
    }
  
    if (adjustment) {
      confirmedReservation.adjustment = true;
      confirmedReservation.adjustmentFee = Number(setupPrice);
    } else {
      confirmedReservation.adjustment = false;
    }

    if (onSite) {
      confirmedReservation.onSite = true;
      confirmedReservation.onSiteDuration = Number(onSiteDuration);
      confirmedReservation.onSiteFee = Number(onSitePrice);
    } else {
      confirmedReservation.onSite = false;
    }
  
    if (dismantling) {
      confirmedReservation.dismantling = true;
      confirmedReservation.dismantlingFee = Number(dismantlingPrice);
    } else {
      confirmedReservation.dismantling = false;
    }

    if (addPickup) {
      confirmedReservation.addPickup = true;
      confirmedReservation.pickupAddress = pickupAddress;
      confirmedReservation.pickupFee = Number(pickupPrice);
    } else {
      confirmedReservation.addPickup = false;
    }
  
    if (discount) {
      confirmedReservation.discount = true;
      confirmedReservation.discountAmount = Number(discountPrice);
    } else {
      confirmedReservation.discount = false;
    }

    //Saving to Firebase Database
    try {
      const updateRef = doc(db, "reservations", confirmedReservation.id);
      await updateDoc(updateRef, {
        ...confirmedReservation
      });
      
    } catch (error) {

      console.error('Erreur lors de la mise à jour de la réservation', error);
      alert('Erreur lors de la mise à jour de la réservation: \n\n' + error);
      setLoading(false);
      return;

    }

    if (isAddToCalendarChecked) {
      const calendarEvent = createCalendarEvent(confirmedReservation);
      //Adding event to calendar
  
      setLoadingMessage("Ajout au calendrier");
      try {
  
        const addToCalendar = httpsCallable(functions, "ac972d5d00c5a2401d89887aa33e2dbd888fbf21e1eb1f86d3496b35");
        const result = await addToCalendar({myToken: accessToken, calendarEvent: calendarEvent});
        const data = result.data as CalendarResultData;
        if (data && data.accessToken) {
          setAccessToken(data.accessToken);
        }
        
        setLoading(false);
        navigate('/');
  
      } catch (error) {
        console.log(error);
        alert(`Erreur d'ajout au calendrier: \n${error}`);
      }
    }


    if (isSendConfirmationChecked) {
      //SendingEmail
      setLoadingMessage("Envoie de la confirmation");

      try {

        const sendEmail = httpsCallable(functions, "d04af80e4b9d810535949bc3936089caa8655cb17045d78d5d8aa6db");
        const emailToSend = confirmedReservation.email;
        const htmlContent = generateConfirmationEmail(confirmedReservation, mailNote);

        let attachments: Array<{ content: string; filename: string; type: string; disposition: string }> = [];

        if (isIncludeQRFacture) {
          // Generate the invoice PDF
          const pdfBlob = await generateInvoiceFrom(generateUpdatedReservation(), true, segmentString(invoiceRecipient, 20));
          const base64Pdf = await pdfBlobToBase64(pdfBlob);
      
          // Add the PDF as an attachment
          attachments.push({
            content: base64Pdf,
            filename: 'Facture_' + confirmedReservation.firstName + '_'+ confirmedReservation.lastName + '.pdf',
            type: 'application/pdf',
            disposition: 'attachment',
          });
        }

        if (isIncludeQuote) {
          //Generate Quote PDF
          const pdfBlob = await generateInvoiceFrom(generateUpdatedReservation(), false, segmentString(invoiceRecipient, 20));
          const pdfBase64 = await pdfBlobToBase64(pdfBlob);

          // Add the PDF as an attachment
          attachments.push({
            content: pdfBase64,
            filename: 'Devis_' + confirmedReservation.firstName + '_'+ confirmedReservation.lastName + '.pdf',
            type: 'application/pdf',
            disposition: 'attachment',
          });
        }
      
        await sendEmail({
          to: emailToSend,
          subject: "Confirmation de réservation | AirSound",
          html: htmlContent,
          attachments: attachments,
        });

        await sendEmail({
          to: 'projecteurairsound@gmail.com',
          subject: "BACKUP - Confirmation de réservation | AirSound",
          html: htmlContent,
          attachments: attachments,
        });

        setLoading(false);
        navigate('/');
      
      } catch (error) {
        console.error('Erreur lors de l\'envoi de l\'email', error);
        alert('Erreur lors de l\'envoi de l\'email: \n\n' + error);
        setLoading(false);
      }
    }
  };


function pdfBlobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = (reader.result as string).split(',')[1]; // Remove the data URL prefix
      resolve(base64String);
    };

    reader.onerror = () => {
      reject(new Error("Failed to convert Blob to Base64"));
    };

    reader.readAsDataURL(blob);
  });
}




  return (
    <div className="container mx-auto p-4">

      <button
        onClick={() => navigate('/')}
        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded flex items-center"
      >
        <svg className="mr-2 w-4 h-4" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
          <path d="M15 19l-7-7 7-7"/>
        </svg>
        Retour
      </button>
      <br/>
      <h1 className="text-xl font-bold my-4 flex justify-center">NOUVELLE RÉSERVATION</h1>
      <br/>

      <div className="bg-white shadow-md rounded-lg p-6 mb-6">
        <h2 className="text-xl font-bold mb-4">Réservation #{reservation?.reservationNumber}</h2>
        <br/>
        <div className="grid grid-cols-1 gap-4">
          {reservation?.customerMessage && (
            <div className="flex items-start">
              <FaCommentDots className="text-gray-500 mt-1 mr-2" />
              <div>
                <strong>Message du client :</strong>
                <div className="mt-2">{reservation.customerMessage}</div>
              </div>
            </div>
          )}
          <div>
            {comments.map((comment, index) => (
              <div key={index} className="p-2 mt-2 bg-gray-100 rounded">
                {comment.text}
              </div>
            ))}
          </div>
        </div>
        <input
          type="text"
          value={comment}
          placeholder='Ajouter une note interne'
          onChange={(e) => setComment(e.target.value)}
          className="border rounded p-2 w-full mt-4"
        />

        <div className="flex justify-end">
          <button onClick={addComment} className="mt-2 bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
            Ajouter
          </button>
        </div>
      </div>
      <br/>

      <form 
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault(); // Prevent form submission on Enter
          }
        }}
        onSubmit={sendConfirmation}
        className="mb-4">


        {/*Renseignements*/}
        <h2 className="text-xl font-bold my-4">{`1) Informations client`}</h2>
  

        <div className="flex-1">
          <label htmlFor="firstname" className="block text-gray-700 text-sm font-bold mb-2">
            Prénom
          </label>
          <input
            type="text"
            name="firstname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>
        
        <div className="flex-1">
          <label htmlFor="lastname" className="block text-gray-700 text-sm font-bold mb-2">
            Nom
          </label>
          <input
            type="text"
            name="lastname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>

        <div className="flex-1">
          <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-2">
            Email
          </label>
          <input
            type="text"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>
        
        <div className="flex-1">
          <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">
            Téléphone
          </label>
          <input
            type="text"
            name="phoneNumber"
            placeholder='+41 ...'
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>
        <div className="flex gap-4 mb-4">
          <div className="flex-1">
            <label htmlFor="nip" className="block text-gray-700 text-sm font-bold mb-2">
              NIP {'(Option)'}
            </label>
            <input
              type="number"
              name="nip"
              value={nip}
              onChange={(e) => setNip(parseInt(e.target.value))}
              placeholder='ex: 1030'
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        
          <div className="flex-1">
            <label htmlFor="country" className="block text-gray-700 text-sm font-bold mb-2">
              Pays
            </label>
            <input
              type="text"
              name="country"
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>
        
        <br/>

        <h2 className="text-xl font-bold my-4">{`2) Liste du matériel`}</h2>

        <h3 className="text-lg my-4 font-bold justify-center flex">Tarif est pour les factures uniquement. Inscrire 0 pour OFFERT, laisser vide pour INCLUS</h3>

        {productList.map((product, index) => (
          <div key={index} className="flex flex-wrap -mx-3 mb-4 items-center">
          <div className="w-full sm:w-6/12 px-3">
              <input
                type="text"
                placeholder='Nom du produit'
                value={product.title}
                onChange={(e) => handleProductNameChange(index, e.target.value)}
                className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-3/12 sm:w-2/12 px-3">
              <input
                type="number"
                min="0"
                placeholder='Quantité'
                value={product.quantity === 0 ? '' : product.quantity}
                onChange={(e) => handleQuantityChange(index, parseInt(e.target.value))}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-3/12 sm:w-2/12 px-3">
              <input
                type="number"
                min="0"
                placeholder={`Tarif unité (CHF)`}
                value={product.price}
                onChange={(e) => handlePriceChange(index, parseInt(e.target.value))}
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              />
            </div>
            <div className="w-1/12 px-3 flex justify-center">
              <button
                onClick={() => handleRemoveProduct(index)}
                type="button"
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
              >
                <MdCancel />
              </button>
            </div>
          </div>
        ))}

        <div className="flex flex-wrap -mx-3 mb-4 items-center">
          <div className="w-full sm:w-6/12 px-3">
            <input
              type="text"
              placeholder="Ajouter un produit"
              value={newProductTitle}
              onChange={(e) => setNewProductTitle(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="w-3/12 sm:w-2/12 px-3">
            <input
              type="number"
              min="0"
              placeholder="Quantité"
              value={newProductQuantity === 0 ? '' : newProductQuantity}
              onChange={(e) => setNewProductQuantity(parseInt(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="w-3/12 sm:w-2/12 px-3">
            <input
              type="number"
              min="0"
              step="0.01"
              placeholder="Tarif unité (CHF)"
              value={newProductPrice}
              onChange={(e) => setNewProductPrice(parseFloat(e.target.value))}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
          <div className="w-1/12 px-3 flex justify-center">
            <button
              onClick={handleAddProduct}
              type="button"
              className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded"
            >
              +
            </button>
          </div>
        </div>




        <br/>
        <h2 className="text-xl font-bold my-4">{`3) Détails`}</h2>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Prise en charge
        </legend>
        <input
          type="datetime-local"
          name="returnDateAndHour"
          value={pickupDate}
          onChange={(e) => setPickupDate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
        <br/>
        <br/>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Retour
        </legend>
        <input
          type="datetime-local"
          name="returnDateAndHour"
          value={returnDate}
          onChange={(e) => setReturnDate(e.target.value)}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          required
        />
        <br/>
        <br/>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Coefficient de location
        </legend>

        <input type="number"
          name="rentalCoefficient"
          step={0.25}
          id="rentalCoefficient"
          min="0"
          value={rentalCoefficient}
          onChange={(e) => setRentalCoefficient(parseFloat(e.target.value))}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Définir un coefficient de location"
          required
        />
        <br/>
        <br/>
        <fieldset className="mb-4 flex flex-col gap-4">
          <legend className="block text-gray-700 text-sm font-bold mb-2">
            Moyen de paiement
          </legend>
          <div className="flex flex-col sm:flex-row gap-4">
            <label className="flex items-center gap-2">
              <input type="radio"
              name="paymentMethod"
              className='accent-purple-500'
              value="Twint"
              checked={paymentMethod !== "Facture"}
              onChange={(e) => {setPaymentMethod(e.target.value);
                setIsIncludeQRFacture(false);
              }}
              required />
              À la remise
            </label>
            <label className="flex items-center gap-2">
              <input type="radio"
              name="paymentMethod"
              className='accent-purple-500'
              checked={paymentMethod === "Facture"}
              onChange={(e) => {
                setPaymentMethod(e.target.value);
                setIsIncludeQRFacture(true);
              }
              }
              value="Facture" />
              Sur QR Facture
            </label>
          </div>
        </fieldset>
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Montant total lié au matériel (CHF)
        </legend>

        <input type="number"
          name="productTotal"
          id="productTotal"
          min="0"
          value={productTotal}
          onChange={(e) => setProductproductTotal(parseInt(e.target.value))}
          className="shadow appearance-none border rounded py-2 px-3 w-full text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="Entrer un montant de réservation (CHF)"
          required
        />
        <br/>
        <br/>


        <br/>
        {/* Caution */}
        <h2 className="text-xl font-bold my-4">{`4) Définir la caution`}</h2>
      

        <fieldset className="mb-4 flex flex-col gap-4">
          <legend className="block text-gray-700 text-sm font-bold mb-2">
            Prévoir une caution ?
          </legend>
          <div className="flex flex-col sm:flex-row gap-4">
            <label className="flex items-center gap-2">
              <input type="radio"
              name="depositMethod"
              className='accent-purple-500'
              value="Twint"
              checked={depositMethod !== "no"}
              onChange={(e) => setDepositMethod(e.target.value)}
              required />
              Oui, à la remise
            </label>
            <label className="flex items-center gap-2">
              <input type="radio"
              name="depositMethod"
              className='accent-purple-500'
              checked={depositMethod === "no"}
              onChange={(e) => setDepositMethod(e.target.value)}
              value="no" />
              Non, pas de caution
            </label>
          </div>
        </fieldset>

        
        <legend className="block text-gray-700 text-sm font-bold mb-2">
          Rentrez le montant (CHF)
        </legend>

        <input type="number"
          name="depositAmount"
          id="depositAmount"
          min="0"
          value={depositAmount}
          disabled={depositMethod === 'no'}
          onChange={(e) => setDepositAmount(parseInt(e.target.value))}
          className="shadow appearance-none border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          placeholder="200"
          required
        />
        
        <br/>
        <br/>
        <br/>
        <h2 className="text-xl font-bold my-4">{`5) Options (CHF)`}</h2>

        {/* Ajouter une livraison */}
        <div className="mb-4">
          <label htmlFor="addDelivery" className="flex items-center">
            <input
              type="checkbox"
              checked={addDelivery}
              id="addDelivery"
              onChange={handleAddDeliveryChange}
              className="mr-2"
            />
            Livraison
          </label>
          <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
            <input
              type="text"
              onChange={(e) => setDeliveryAddress(e.target.value)}
              value={deliveryAddress}
              placeholder="Adresse de livraison (apparaît sur la facture)"
              disabled={!addDelivery}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <input
              type="number"
              placeholder="Tarif (CHF)"
              value={deliveryPrice}
              onChange={(e) => setDeliveryPrice(e.target.value)}
              disabled={!addDelivery}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>

        <br/>
        {/* Installation */}
        <div className="mb-4">
          <label htmlFor="installation" className="flex items-center">
            <input
              type="checkbox"
              checked={installation}
              onChange={handleInstallationChange}
              className="mr-2"
            />
            Installation
          </label>
          <input
            type="number"
            placeholder="Tarif (CHF)"
            value={installationPrice}
            disabled={!installation}
            onChange={(e) => setInstallationPrice(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <br/>
        {/* Réglage */}
        <div className="mb-4">
          <label htmlFor="adjustment" className="flex items-center">
            <input
              type="checkbox"
              checked={adjustment}
              onChange={handleAdjustmentChange}
              className="mr-2"
            />
            Réglages et tests techniques
          </label>
          <input
            type="number"
            placeholder="Tarif (CHF)"
            disabled={!adjustment}
            value={setupPrice}
            onChange={(e) => setSetupPrice(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <br/>
        {/* Présence sur place */}
        <div className="mb-4">
          <label htmlFor="onSite" className="flex items-center">
            <input
              type="checkbox"
              checked={onSite}
              id="onSite"
              onChange={handleOnSiteChange}
              className="mr-2"
            />
            Présence sur place
          </label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <input
              type="number"
              placeholder="Durée (h)"
              onChange={(e) => setOnSiteDuration(e.target.value)}
              value={onSiteDuration}
              disabled={!onSite}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <input
              type="number"
              placeholder={`Tarif à l'heure (CHF)`}
              disabled={!onSite}
              value={onSitePrice}
              onChange={(e) => setOnSitePrice(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>

        <br/>
        {/* Démontage */}
        <div className="mb-4">
          <label htmlFor="dismantling" className="flex items-center">
            <input
              type="checkbox"
              checked={dismantling}
              id="dismantling"
              onChange={handleDismantlingChange}
              className="mr-2"
            />
            Démontage
          </label>
          <input
            type="number"
            placeholder="Tarif (CHF)"
            disabled={!dismantling}
            value={dismantlingPrice}
            onChange={(e) => setDismantlingPrice(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>

        <br/>
        {/* Ajouter une reprise */}
        <div className="mb-4">
          <label htmlFor="addPickup" className="flex items-center">
            <input
              type="checkbox"
              id="addPickup"
              checked={addPickup}
              onChange={handleAddPickupChange}
              className="mr-2"
            />
            Reprise
          </label>
          <div className={"grid grid-cols-1 md:grid-cols-2 gap-4"}>
            <input
              type="text"
              placeholder="Adresse (si différente de livraison)"
              disabled={!addPickup}
              value={pickupAddress}
              onChange={(e) => setPickupAddress(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <input
              type="number"
              placeholder="Tarif (CHF)"
              disabled={!addPickup}
              value={pickupPrice}
              onChange={(e) => setPickupPrice(e.target.value)}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
          </div>
        </div>

        <br/>
        {/* Geste commercial */}
        <h2 className="text-xl font-bold my-4">{`6) Geste commercial (CHF)`}</h2>
        <div className="mb-4">
          <label htmlFor="discount" className="flex items-center">
            <input
              type="checkbox"
              id="discount"
              checked={discount}
              onChange={handleDiscountChange}
              className="mr-2"
            />
            Remise sur total
          </label>
          <input
            type="number"
            placeholder="Montant (CHF)"
            disabled={!discount}
            value={discountPrice}
            onChange={(e) => setDiscountPrice(e.target.value)}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>


        <br/>
        <div className="flex justify-end">
          <div className="w-full md:w-1/2 lg:w-1/3">
            <div className="mb-4 bg-gray-100 p-4 rounded-lg">
              <h3 className="text-lg font-semibold mb-2 text-center md:text-left">Récapitulatif</h3>
              <div className="grid grid-cols-1 gap-4">
                <div className="mb-2">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    Montant Total Calculé
                  </label>
                  <input
                    type="text"
                    readOnly
                    value={calculatedSum}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-xl font-bold my-4">{`7) Note`}</h2>
        <div className="mb-4">
          <label htmlFor="note" className="block text-gray-700 text-sm font-bold mb-2">
            Note pour le jour J
          </label>
          <textarea name="note" 
          value={note}
          onChange={(e) => setNote(e.target.value)}
          rows={3}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
        </div>
        <br/>

        <h2 className="text-xl font-bold my-4">{`8) Destinataire pour la facture`}</h2>
        <div className="mb-4">
          <label htmlFor="note" className="block text-gray-700 text-sm font-bold mb-2">
            Renseigner tout en une ligne, le découpage est automatique.
          </label>
          <textarea name="recipient" 
          value={invoiceRecipient}
          onChange={(e) => setInvoiceRecipient(e.target.value)}
          rows={3}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"></textarea>
        </div>

        <br/>
        {/*Note dans le mail de validation*/}
        <h2 className="text-xl font-bold my-4">{`9) Note pour le client (Option)`}</h2>
        <div className="mb-4">
          <label htmlFor="note" className="block text-gray-700 text-sm font-bold mb-2">
            Espace de personnalisation du mail de validation
          </label>
          <textarea name="mailnote"
          rows={3}
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
          </textarea>
        </div>
        <br/>

        <div className="flex justify-end">
          <div className="mb-4">
            <label>
              <input
                type="checkbox"
                className='accent-purple-500'
                name="checkMail"
                checked={isAddToCalendarChecked}
                onChange={(e) => setIsAddToCalendarChecked(e.target.checked)}
              />
              Ajouter au calendrier
            </label>
          </div>
        </div>
        <div className="flex justify-end">
          <div className="mb-4">
            <label>
              <input
                type="checkbox"
                className='accent-purple-500'
                name="checkMail2"
                checked={isSendConfirmationChecked}
                onChange={(e) => setIsSendConfirmationChecked(e.target.checked)}
              />
              Envoyer la confirmation par email
            </label>
          </div>
        </div>
        <div className="flex justify-end">
          {(paymentMethod !== "Facture") && (isIncludeQRFacture) && (isSendConfirmationChecked) && (
            <h4 className="font-bold text-orange-500 mb-2"> ⚠️ Vous avez coché paiement à la remise ! ⚠️&nbsp;</h4>
          )}
          <div className="mb-4">
            <label>
              <input
                type="checkbox"
                className="accent-purple-500"
                name="checkMail3"
                disabled={!isSendConfirmationChecked}
                checked={isIncludeQRFacture}
                onChange={(e) => setIsIncludeQRFacture(e.target.checked)}
              />
              Inclure la facture dans le mail
            </label>
          </div>
        </div>

        <div className="flex justify-end">
          <div className="mb-4">
            <label>
              <input
                type="checkbox"
                className="accent-purple-500"
                name="checkMail4"
                disabled={!isSendConfirmationChecked}
                checked={isIncludeQuote}
                onChange={(e) => setIsIncludeQuote(e.target.checked)}
              />
              Inclure le devis dans le mail
            </label>
          </div>
        </div>

        <br/>
        <div className="flex justify-end">
        {!isSumCorrect && (
            <h4 className="font-bold text-orange-500 mb-2"> ⚠️ La somme des tarifs n'est pas égale au tarif total du matériel ⚠️&nbsp;</h4>
          )}
        </div>
        <div className="flex justify-end">
          <button type="submit" disabled={loading} className="bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
            Valider la réservation
          </button>
        </div>
        <br/>
        <br/>
        <div className="flex justify-end">
          <button type="button" onClick={handleSaveOnly} disabled={loading} className="bg-pink-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded">
            Sauvegarder uniquement
          </button>
        </div>
        <br/>        
        <div className="flex justify-end  space-x-4">
          <button
            onClick={downloadInvoice}
            type="button"
            className="inline-flex bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            <FaDownload className="mr-2" />
          </button>
          <button
            onClick={() => generateInvoicePdf(true)}
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Visualiser la facture
          </button>
        </div>
        <br/>
        <div className="flex justify-end space-x-4"> 
          <button
            onClick={downloadQuote}
            type="button"
            className="inline-flex bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded">
            <FaDownload className="mr-2" />
          </button>
          <button
            onClick={() => generateInvoicePdf(false)}
            type="button"
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Visualiser le devis
          </button>
        </div>    
        <br/>
      </form>

      {pdfUrl && (
            <iframe
            src={pdfUrl}
            className="w-full h-screen mt-4 border"
            title="PDF Preview"
            />
        )}

      <DeleteMenu reservationStatus={reservation?.status} onStatusChange={handleStatusChange} />
    </div>
  );  
};

export default NewReservation;
